.btn-styling {
    color: white;
    background-color: #003400;
}


.btn-styling:hover {
    color: white;
    background-color: #003400;
}


.breadcrumb-color {
    background-color: #003400;
    color: white;
}




  .border-styling {
    border-color: #003400 ;
  }


  .custom-button {
    background-color: red; /* Change this to your desired color */
    color: white; /* Text color */
    /* Add any other styles you want */
  }

  /* 1. Home Page Css */
/* 2. Home Page Css */

/* ================== 1. Constants ================== */
/* ================== 1. Home Page CSS ================== */
/* ================== 2. About Us Page CSS ================== */

/* Python
  Django
  SCSS 
*/