body{
    overflow: scroll !important;
}

.login{

    .logo{
        width: 60%;
    }

    form{
        background: #faf5e9;
        padding: 50px;
        border: 1px solid transparent;
        border-radius: 20px;
        box-shadow: 4px 4px 1px 1px rgb(255 255 255 / 30%);
    }

    button{
        width: 100%;
        height: 50px;
    }

}

label{
    color: #003400 !important;
    font-weight: 500;
    margin-bottom: 5px !important;
}

.form-control, select, textarea{
    border: 1px solid #003400;
    height: 50px;
    border-radius: 5px !important;
}

textarea{
    height: 100px !important;
}

.score-header {
    border: 1px solid #000; 
    background-color: #f0f0f0; 
  }

.guide-table{
    th, td{
        border: 1px solid #003400;
        text-align: left;
        padding: 0 5px;
        font-size: 12px;
        font-weight: 600;
    }
    .bcs-score{
        font-weight: bold;
        font-size: 26px;
    }
}


.login-wrapper{
    background-color: #003400;
    height: 100vh;
}


.forgot-password-link{
    text-decoration: none;
    color: #003400;
    margin-top: 20px;   
}


@media screen and (max-width: 400px) {
    .guide-table{
        th, td{
            border: .1px solid #003400;
            text-align: left;
            padding: 0 2px;
            font-size: 8px;
        }
        .bcs-score{
            font-size: 20px;
        }
    }
}



.table-header{
    padding: 20px !important;
    background-color: #003400 !important;
    font-weight: 'bold' !important;
    color: white !important

}


.table-cell-style{
    padding: 20px!important;
    border: 1px solid #003400 !important;
  }

  @media screen and (max-width: 576px) {

.table-header{
    padding: 10px !important;
    background-color: #003400 !important;
    font-weight: 'bold' !important;
    color: white !important;
    font-size: 0.7rem !important;
}


.table-cell-style{
    padding: 10px !important;
    border: 1px solid #003400 !important;
    font-size: 0.7rem !important;
  }
  }
  
