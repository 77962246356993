.text-primary{
    color: #003400 !important;
}


.text-bold{
    font-weight: bold;
}

.shadow{
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.25) !important;
}

.br-10{
    border-radius: 10px;
}


.custom-container{
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid lightgray;
    border-radius: 10px;
}

.breadcrumb-item{
    font-weight: 500;
}

.border-green{
    border: 1px solid #003400;
    border-radius: 5px;
}

.cursor-pointer{
    cursor: pointer !important;
}