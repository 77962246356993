.btn-green{
    background-color: #003400 !important;
    color: white !important;
    border: 1px solid transparent !important; 
    transition: .3s;
}
    
.btn-green:hover{
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.3);
}

.btn-white{
    background-color: #003400 !important;
    color: white !important;
    border: 1px solid white !important; 
    transition: .3s;
}
    
.btn-white:hover{
    box-shadow: 1px 1px 8px 0px rgba(255, 255, 255, 0.3);
}


.back-btn{
    color: white;
    cursor: pointer;
}
